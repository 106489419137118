<template>
  <loading-view />
</template>
<script>
import LoadingView from './LoadingView.vue'
export default {
  components: { LoadingView },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('index-view-entered', to.path, from.path)
    })
  }
}
</script>
